import videojs from "video.js";

export const videoTracks = [
    new videojs.AudioTrack({
        id: 'en',
        kind: 'main',
        label: 'English',
        language: 'en'
    }),
    new videojs.AudioTrack({
        id: 'de',
        kind: 'main',
        label: 'German',
        language: 'de'
    }),
    new videojs.AudioTrack({
        id: 'bg',
        kind: 'main',
        label: 'Bulgarian',
        language: 'bg'
    }),
    new videojs.AudioTrack({
        id: 'fr',
        kind: 'main',
        label: 'French',
        language: 'fr'
    }),
    new videojs.AudioTrack({
        id: 'ja',
        kind: 'main',
        label: 'Japanese',
        language: 'ja'
    }),
    new videojs.AudioTrack({
        id: 'es',
        kind: 'main',
        label: 'Spanish',
        language: 'es'
    }),
    new videojs.AudioTrack({
        id: 'mk',
        kind: 'main',
        label: 'Macedonian',
        language: 'mk'
    }),
    new videojs.AudioTrack({
        id: 'ro',
        kind: 'main',
        label: 'Romanian',
        language: 'ro'
    }),
    new videojs.AudioTrack({
        id: 'ru',
        kind: 'main',
        label: 'Russian',
        language: 'ru'
    }),
    new videojs.AudioTrack({
        id: 'sl',
        kind: 'main',
        label: 'Slovenian',
        language: 'sl'
    }),
    new videojs.AudioTrack({
        id: 'sr',
        kind: 'main',
        label: 'Serbian',
        language: 'sr'
    }),
    new videojs.AudioTrack({
        id: 'tr',
        kind: 'main',
        label: 'Turkish',
        language: 'tr'
    }),
    new videojs.AudioTrack({
        id: 'zu',
        kind: 'main',
        label: 'Zulu',
        language: 'zu'
    }),
    new videojs.AudioTrack({
        id: 'hr',
        kind: 'main',
        label: 'Croatian',
        language: 'hr'
    }),
];