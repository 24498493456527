import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import VideoJS from "../UI/VideoJS";
import {movieData} from "../../consts/MovieData";
import {videoTracks} from "../../consts/VideoTracks";

export const MovieDetails = () => {
    const movies = movieData;
    const [movie, setMovie] = useState(null);
    const [currentTime, setCurrentTime] = useState(0);
    const params = useParams();
    const videoRef = useRef(null);
    // const [audioPlayer, setAudioPlayer] = useState(new Audio());
    const [videoJsOptions, setVideoJsOptions] = useState({
        autoplay: false,
        controls: true,
        responsive: true,
        preload: true,
        fluid: true,
        sources: [{
            src: `https://isynchro-hub.ireason.mk/${params.id}/en.mp4`,
            type: 'video/mp4'
        }],
        audioTracks: videoTracks
    });

    const handlePlayerReady = (player) => {
        videoRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            player.log('player is waiting');
        });

        player.on("ready", () => {
            let tracks = player.audioTracks();
            // listen to the change event
            if (currentTime !== 0) {
                player.currentTime(currentTime);
                player.play();
            }

            tracks.addEventListener('change', function () {

                // Log the currently enabled AudioTrack label.
                for (var i = 0; i < tracks.length; i++) {
                    var track = tracks[i];

                    if (track.enabled) {
                        setCurrentTime(player.currentTime());
                        setVideoJsOptions(prevState => {
                            return {
                                ...prevState,
                                autoplay: player.paused,
                                currentTime: player.currentTime(),
                                sources: [{
                                    src: `https://isynchro-hub.ireason.mk/${params.id}/${track.language}.mp4`,
                                    type: 'video/mp4'
                                }]
                            }
                        })
                        return;
                    }
                }
            });
        });

        player.on('play', (event) => {
            player.log('PLAY');
        });

        player.on('pause', (event) => {
            player.log('Pause');
        });

        player.on('dispose', () => {
            player.log('player will dispose');
        });
    };

    useEffect(() => {
        document.body.scrollTo({top: 0, left: 0, behavior: "smooth"});
        setMovie(movies.find(m => m.id == params.id));
        setCurrentTime(0);

        setVideoJsOptions(prevState => {
            return {
                ...prevState,
                autoplay: false,
                currentTime: 0,
                sources: [{
                    src: `https://isynchro-hub.ireason.mk/${params.id}/en.mp4`,
                    type: 'video/mp4'
                }]
            }
        })
    }, [params.id])

    return (
        <>
            <div className="bg-dark bg-opacity-75 p-4 pb-0">
                <div className="container">
                    <div className="in-breadcrumb">
                        <div className="row py-3">
                            <div className={"col mx-auto text-center text-white"}>
                                <h2 className={"text-white"}>{movie?.name}</h2>
                                <p className={"p-3"}>
                                    <span>{movie?.runtime} </span> |
                                    <span> {movie?.year} </span> |
                                    <span> {movie?.genres.map((genre, index) => {
                                        const text = index < movie?.genres.length - 1 ? ", " : "";
                                        return genre + text;
                                    })} </span> |
                                    <span> U/A {movie?.age} </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="movie-details-video-content-wrap">
                    <div className="video-wrap">
                        <VideoJS options={videoJsOptions} onReady={handlePlayerReady}/>
                    </div>

                    <div className="movie-details-content pb-3">
                        <div className="movie-details-info">
                            <table>
                                <tbody>
                                <tr>
                                    <td style={{width: "150px"}} className={"text-danger fw-bold"}>Director:</td>
                                    <td className={"text-white ps-2"}>{movie?.director}</td>
                                </tr>
                                <tr>
                                    <td style={{width: "150px"}} className={"text-danger fw-bold"}>Starring:</td>
                                    <td className={"text-white ps-2"}>
                                        {movie?.stars.map((star, index) => {
                                            const text = index < movie?.stars.length - 1 ? ", " : "";
                                            return star + text
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width: "150px"}} className={"text-danger fw-bold"}>Audio support:</td>
                                    <td className={"text-white ps-2"}>
                                        {videoTracks.map((track, index) => {
                                            const text = index < videoTracks.length - 1 ? ", " : "";
                                            return `${track.label} (${track.language.toUpperCase()})${text}`
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width: "150px"}} className={"text-danger fw-bold"}>Description:</td>
                                    <td className={"text-white ps-2"}>{movie?.description}</td>
                                </tr>
                                <tr>
                                    <td style={{width: "150px"}} className={"text-danger fw-bold"}>Storyline:</td>
                                    <td className={"text-white ps-2"}>{movie?.storyline}</td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>


                </div>
                {/*<MovieOwlList title={"Recommended movies"} movies={movies} itemsToShow={5}/>*/}

            </div>
        </>
    )
}