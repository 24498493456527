import React from 'react';
import OwlCarousel from "react-owl-carousel";
import {Link} from "react-router-dom";
import {BsDot} from "react-icons/bs";

export const MovieOwlList = ({movies, title, itemsToShow, description}) => {
    return (
        <div id={"library"} className="movie-list section-padding-lr section-pt-50 bg-black py-3 mx-0 px-0">
            <div className="container-fluid">
                <div className="section-title-4 footer-border-top text-center mx-0 pt-5 pb-3">
                    <div className={"row my-5"}>
                        <div className={"col-12"}><h2 className={"font-lg  text-light"}>{title}</h2></div>
                        {
                            description && <>
                                <div className={"col-sm-12 col-lg-4 m-auto"}>
                                    <BsDot size={20} className={"text-gray my-auto ms-auto"}/>
                                    <BsDot size={25} className={"text-gray my-auto"}/>
                                    <BsDot size={30} className={"text-gray my-auto me-auto"}/>
                                </div>
                                <div className={"col-sm-12 col-lg-4"}>
                                    <p className={"text-light font-sm text-center"}>
                                        {description}
                                    </p>
                                </div>
                                <div className={"col-sm-12 col-lg-4 m-auto"}>
                                    <BsDot size={30} className={"text-gray my-auto ms-auto"}/>
                                    <BsDot size={25} className={"text-gray my-auto"}/>
                                    <BsDot size={20} className={"text-gray my-auto me-auto"}/>
                                </div>
                            </>
                        }

                    </div>
                </div>

                <div className={"movie-slider-active nav-style-2 slick-initialized slick-slider mb-5"}>
                    <div className={"slick-list draggable"}>
                        <div className={"slick-track"}>
                            <OwlCarousel items={movies}
                                         className="owl-theme"
                                         loop
                                         dots={false}
                                         autoplay={true}
                                         autoplayTimeout={5000}
                                         autoplaySpeed={1300}
                                         autoplayHoverPause={true}
                                         height={"100%"}
                                         responsive={
                                             {
                                                 0: {
                                                     items: 1
                                                 },
                                                 600: {
                                                     items: 2
                                                 },
                                                 1000: {
                                                     items: 3
                                                 },
                                                 1400: {
                                                     items: 4
                                                 },
                                                 1700: {
                                                     items: 5
                                                 }
                                             }
                                         }
                                         margin={0}>
                                {
                                    movies.map(movie => {
                                        return <div key={movie.id} className="item movie-wrap-plr slick-slide slick-active p-0" data-slick-index="-6" id=""
                                                    aria-hidden="true" tabIndex="-1" style={{width: "289px"}}>
                                            <div className="movie-wrap text-center">
                                                <div className="movie-img">
                                                    <Link to={`/movie/${movie.id}`} tabIndex="-1">
                                                        <img src={`https://isynchro-hub.ireason.mk/poster/${movie.id}.jpg`} className={"card-img"} alt=""/>
                                                    </Link>
                                                    {/*<button title="Watchlist" className="Watch-list-btn" type="button" tabIndex="-1">*/}
                                                    {/*    <i className="zmdi zmdi-plus"/></button>*/}
                                                </div>
                                                <div className="movie-content">
                                                    <h3 className="title"><Link to={`/movie/${movie.id}`} tabIndex="-1">{movie.name}</Link>
                                                    </h3>
                                                    <span>Quality : HD</span>
                                                    <div className="movie-btn">
                                                        <Link to={`/movie/${movie.id}`} className="btn-style-hm4-2 animated" tabIndex="-1">
                                                            Watch Now</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    })
                                }

                            </OwlCarousel>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}