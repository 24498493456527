export const movieData = [
    // {
    //     id: 0,
    //     name: "Batman the dark knight"
    // },
    // {
    //     id: 1,
    //     name: "Dont worry"
    // },
    // {
    //     id: 2,
    //     name: " LA LA LA Land"
    // },
    // {
    //     id: 3,
    //     name: "Logan"
    // },
    // {
    //     id: 4,
    //     name: "Pirates of the caribbean"
    // },
    // {
    //     id: 5,
    //     name: "Prespav"
    // },
    // {
    //     id: 6,
    //     name: "Skyfall"
    // },
    {
        id: 7,
        name: "The hunger games: Catching fire",
        director: "Francis Lawrence",
        stars: [
            "Jennifer Lawrence", "Josh Hutcherson", "Liam Hemsworth"
        ],
        genres: ["Action", "Adventure", "Sci-Fi", "Thriller"],
        runtime: "2 hour 26 minutes",
        year: "2013",
        age: "14+",
        description: "Katniss Everdeen and Peeta Mellark become targets of the Capitol after their victory in the 74th Hunger Games sparks a rebellion in the Districts of Panem.",
        storyline: "Twelve months after winning the 74th Hunger Games, Katniss Everdeen and her partner Peeta Mellark must go on what is known as the Victor's Tour, wherein they visit all the districts, but before leaving, Katniss is visited by President Snow who fears that Katniss defied him a year ago during the games when she chose to die with Peeta. With both Katniss and Peeta declared the winners, it is fueling a possible uprising. He tells Katniss that while on tour she better try to make sure that she puts out the flames or else everyone she cares about will be in danger."

    },
    {
        id: 8,
        name: "TAKEN",
        director: "Pierre Morel",
        stars: [
            "Liam Neeson", "Maggie Grace", "Famke Janssen"
        ],
        genres: ["Action", "Crime", "Thriller"],
        runtime: "1 hour 30 minutes",
        year: "2008",
        age: "16+",
        description: "A retired CIA agent travels across Europe and relies on his old skills to save his estranged daughter, who has been kidnapped while on a trip to Paris.",
        storyline: "Seventeen year-old Kim is the pride and joy of her father Bryan Mills. Bryan is a retired agent who left the Central Intelligence Agency to be near Kim in California. Kim lives with her mother Lenore and her wealthy stepfather Stuart. Kim manages to convince her reluctant father to allow her to travel to Paris with her friend Amanda. When the girls arrive in Paris they share a cab with a stranger named Peter, and Amanda lets it slip that they are alone in Paris. Using this information an Albanian gang of human traffickers kidnaps the girls. Kim barely has time to call her father and give him information. Her father gets to speak briefly to one of the kidnappers and he promises to kill the kidnappers if they do not let his daughter go free. The kidnapper wishes him \"good luck,\" so Bryan Mills travels to Paris to search for his daughter and her friend."
    },
    {
        id: 9,
        name: "Rocky Balboa",
        director: "Sylvester Stallone",
        stars: [
            "Sylvester Stallone", "Antonio Tarver", "Milo Ventimiglia"
        ],
        genres: ["Action", "Drama", "Sport"],
        runtime: "1 hour 42 minutes",
        year: "2006",
        age: "11+",
        description: "Thirty years after the ring of the first bell, Rocky Balboa comes out of retirement and dons his gloves for his final fight against the reigning heavyweight champ Mason 'The Line' Dixon.",
        storyline: "When he loses a highly publicized virtual boxing match to ex-champ Rocky Balboa, reigning heavyweight titleholder Mason Dixon retaliates by challenging the Itallian Stallion to a nationally televised, 10-round exhibition bout. To the surprise of his son and friends, Rocky agrees to come out of retirement and face an opponent who's faster, stronger and thirty years his junior. With the odds stacked firmly against him, Rocky takes on Dixon in what will become the greatest fight in boxing history, a hard-hitting, action-packed battle of the ages.—Shawn Ashley"
    },
    {
        id: 10,
        name: "steve jobs",
        director: "Danny Boyle",
        stars: [
            "Michael Fassbender", "Kate Winslet", "Seth Rogen"
        ],
        genres: ["Biography", "Drama"],
        runtime: "2 hour 2 minutes",
        year: "2015",
        age: "14+",
        description: "Steve Jobs takes us behind the scenes of the digital revolution, to paint a portrait of the man at its epicenter. The story unfolds backstage at three iconic product launches, ending in 1998 with the unveiling of the iMac.",
        storyline: "His passion and ingenuity have been the driving force behind the digital age. However his drive to revolutionize technology was sacrificial. Ultimately it affected his family life and possibly his health. In this revealing film we explore the trials and triumphs of a modern day genius, the late CEO of Apple inc. Steven Paul Jobs."
    },
    {
        id: 11,
        name: "The godfather",
        director: "Francis Ford Coppola",
        stars: [
            "Al Pacino", "Marlon Brando", "James Caan"
        ],
        genres: ["Crime", "Drama"],
        runtime: "2 hour 55 minutes",
        year: "1972",
        age: "16+",
        description: "The aging patriarch of an organized crime dynasty in postwar New York City transfers control of his clandestine empire to his reluctant youngest son.",
        storyline: "The Godfather \"Don\" Vito Corleone is the head of the Corleone mafia family in New York. He is at the event of his daughter's wedding. Michael, Vito's youngest son and a decorated WW II Marine is also present at the wedding. Michael seems to be uninterested in being a part of the family business. Vito is a powerful man, and is kind to all those who give him respect but is ruthless against those who do not. But when a powerful and treacherous rival wants to sell drugs and needs the Don's influence for the same, Vito refuses to do it. What follows is a clash between Vito's fading old values and the new ways which may cause Michael to do the thing he was most reluctant in doing and wage a mob war against all the other mafia families which could tear the Corleone family apart."
    }
]