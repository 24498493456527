import React, {useRef, useState} from "react";

import {MovieOwlList} from "./MovieOwlList";
import {movieData} from "../../consts/MovieData";
import {FaVolumeMute, FaVolumeUp} from "react-icons/fa";
import {GiEarthAmerica, GiSoundWaves} from "react-icons/gi";
import {MdRecordVoiceOver} from "react-icons/md";

export const HomePage = () => {
    const [isMuted, setIsMuted] = useState(true);
    const videoRef = useRef(null);

    const movies = movieData;

    const applicationInfo = <div className={"container my-sm-0"}>
        <div className="row mx-auto my-5 py-5">
            <div className={"col-12 my-3 mb-5"}>
                <h3 className={"text-light text-center"}>What is iSynchro?</h3>
                <p className={"text-light text-center"}>iSynchro is ai supported voice synchronisation software</p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 my-3 border-end">
                <div className="py-7 text-light text-center">
                    <div className="mb-3">
                        <GiEarthAmerica size={80}/>
                    </div>
                    <div className="lh-1">
                        <h2 className="mb-1 text-light">13+</h2>
                        <span>Languages support</span>
                    </div>
                </div>

            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 my-3 border-end">
                <div className="py-7 text-light text-center">
                    <div className="mb-3">
                        <MdRecordVoiceOver size={80}/>
                    </div>
                    <div className="lh-1">
                        <h2 className="mb-1 text-light">|</h2>
                        <span>Keeps actors voice color</span>
                    </div>
                </div>

            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 my-3 ">
                <div className="py-7 text-light text-center">
                    <div className="mb-3">
                        <GiSoundWaves size={80}/>
                    </div>
                    <div className="lh-1">
                        <h2 className="mb-1 text-light">|</h2>
                        <span>Retains background sound effects</span>
                    </div>
                </div>

            </div>
            {/*<div className="col-md-6 col-lg-3 my-3">*/}
            {/*    <div className="py-7 text-light text-center">*/}
            {/*        <div className="mb-3">*/}
            {/*            <FaFilm size={80}/>*/}
            {/*        </div>*/}
            {/*        <div className="lh-1">*/}
            {/*            <h2 className="mb-1 text-light">5+</h2>*/}
            {/*            <span>Online Videos</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*</div>*/}

        </div>
    </div>

    const onClickHandle = () => {
        setIsMuted(!isMuted)
        if (videoRef.current.paused) {
            videoRef.current.play();
        }
    }

    const promoVideo = <div id={"promo-video"} onClick={onClickHandle}>
        <video
            id={"promo-video"}
            className={"video"}
            ref={(player) => { videoRef.current = player }}
            muted={isMuted}
            autoPlay={true}
        >
            <source src="https://isynchro-hub.ireason.mk/video/promo_video.mp4?id=123" />
        </video>
        <div className="overlay w-100 h-100 text-end">
            {
                !isMuted ?
                <FaVolumeUp size={"35px"} className={"text-white"} />
                : <FaVolumeMute size={"35px"} className={"text-white"} />
            }
        </div>
    </div>

    return (
        <>
            {promoVideo}
            {/*{carouselData}*/}
            {applicationInfo}
            {/*Movie list*/}
            <MovieOwlList id={"library"} title={"Our Movie Collection"} description={"Check the quality of isynchro in our imaginary streaming platform. The videos are specialy selected to have various audio characteristics and show the full functionality of the model."} movies={movies} itemsToShow={5}/>
        </>

    )
}