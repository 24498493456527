import React from "react";
import {FaFacebook, FaLinkedin} from "react-icons/fa";
import {Link} from "react-router-dom";

export const Footer = () => {

    return <>
        <div className="movie-list section-padding-lr section-pt-50 bg-black pb-3 text-white pt-0">
            <div className={"container-fluid"}>
                <footer className="row py-5 my-5 px-5 footer-border-top justify-content-center">
                    <div className="col-sm-6 col-md-3 mt-sm-5 mt-md-0">
                        <h4 className={"text-white mb-3 font-md"}>iReason LLC</h4>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2 font-sm">Always beyond state-of-the-art.</li>
                        </ul>
                        <h4 className={"text-white mb-3 font-md mt-3"}>Quick Links</h4>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2 font-sm"><Link to={"/"} className="nav-link p-0 text-muted">Home</Link></li>
                        </ul>
                    </div>

                    <div id={"contact"} className="col-sm-6 col-md-3 mt-sm-5 mt-md-0">
                        <h4 className={"text-white mb-3 font-md"}>Contact Information</h4>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2 font-sm">3rd Macedonian Brigade 37, Skopje</li>
                            <li className="nav-item mb-2 font-sm">+389 75 753 932</li>
                            <li className="nav-item mb-2 font-sm">contact@ireason.mk</li>
                        </ul>
                    </div>

                    <div className="col-sm-12 col-md-4 mt-sm-5 mt-md-0 ">
                        <a href="https://ireason.mk" className={"mb-3 ms-3"}>
                            <img src={"/logo_belo.png"} width={"100px"}  alt={"iReason"}/>
                        </a>
                        <a href={"https://finki.ukim.mk"} className={"ms-3 mb-3"}>
                            <img src={"/finki_mk.png"} width={"200px"}  alt={"FINKI"}/>
                        </a>
                        <ul className="nav flex-row ms-3">
                            <li className="nav-item me-2 font-sm"><a href="https://www.facebook.com/ireasonmk"><FaFacebook size={40}/></a></li>
                            <li className="nav-item me-2 font-sm"><a href="https://www.linkedin.com/company/ireason/"><FaLinkedin size={40}/></a></li>
                        </ul>

                    </div>
                    <div className={"col-12"}>
                        <p className={"mt-sm-3 mt-md-5 text-center"}>© iReason 2022</p>
                    </div>
                </footer>
            </div>

        </div>
    </>
}