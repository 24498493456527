import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import {MdVideoLibrary} from "react-icons/md";

export const NavigationBar = () => {
    const [search, setSearch] = useState("");
    const [openSearch, setOpenSearch] = useState(false);
    const history = useHistory();

    const searchHandle = (event) => {
        event.preventDefault();
        history.push(`/search?text=${search}`)
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-black">
            <div className="container-fluid p-4">
                <Link className={"navbar-brand fw-bold text-danger ms"} to="/"><img src={"/logo.svg"} width={40} height={"auto"} /></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="collapse navbar-collapse" id="navbarScroll">
                    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll bs-scroll fw-bold">
                        <li className="nav-item">
                            <Link to="/" className={"nav-link"}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/#library" className={"nav-link"}>Library</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/#contact" className={"nav-link"}>Contact</Link>
                        </li>
                    </ul>
                    <div className={"d-flex"}>
                        {
                            openSearch ?
                                <form onSubmit={searchHandle} className={"input-group rounded-pill"}>
                                    <input
                                        placeholder="Search"
                                        type="text"
                                        className={"form-control rounded-pill rounded-end nav-font"}
                                        value={search}
                                        onChange={(event => setSearch(event.target.value))}/>
                                    <button className="btn btn-white rounded-pill rounded-start"><i
                                        className="zmdi zmdi-search nav-font"/></button>
                                </form> : null
                        }

                        <button type={"button"}
                                className={`btn btn-dark bg-black border rounded-circle ${openSearch && "ms-2 "}`}
                                onClick={() => setOpenSearch(!openSearch)}>
                            {
                                !openSearch ?
                                    <i className="zmdi zmdi-search s-open nav-font"/>
                                    :
                                    <i className="zmdi zmdi-close s-close nav-font"/>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    )
}
