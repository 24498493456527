import React, {useEffect, useRef} from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const VideoJS = (props) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const {options, onReady, canPlay} = props;

    useEffect(() => {
        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;

            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, options, () => {
                player.log('player is ready');
                var audioTracks = player.audioTracks();
                if (options.audioTracks.length > 0) {
                    for (const track of options.audioTracks) {
                        audioTracks.addTrack(track)
                    }
                    audioTracks[0].enabled = true;
                }
                onReady && onReady(player);
            });

            // You can update player in the `else` block here, for example:
        } else {
            const player = playerRef.current;
            player.autoplay(options.autoplay);
            player.loop(options.loop);
            player.src(options.sources);
            player.currentTime(options.currentTime || 0)
        }
    }, [options, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player className={"pt-0"}>
            <video ref={videoRef} className={`video-js vjs-big-play-centered`} />
        </div>
    );
}

export default VideoJS;