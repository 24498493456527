import React, {useEffect, useState} from "react";
import {movieData} from "../../consts/MovieData";
import {Link} from "react-router-dom";

export const SearchMovies = (props) => {
    const query = new URLSearchParams(props.location.search);
    const [movies, setMovies] = useState([]);

    useEffect(() => {
        const search = query.get("text")
        if (search) {
            setMovies(movieData.filter(movie => movie.name.toLowerCase().includes(search.toLowerCase())))
        } else {
            setMovies(movieData)
        }
    }, [query])

    return <div className={"row mx-5 my-3"}>
        {
            movies.length > 0 ? <>
                <div className={"col-12 mb-3"}>
                    <h3 className={"text-white"}>Movies according to the content you search for...</h3>
                </div>
                {
                    movies.map(movie => {
                        return <div key={movie.id} className="col-sm-12 col-md-4 col-lg-3 item movie-wrap-plr "
                                    aria-hidden="true" style={{width: "289px"}}>
                            <div className="movie-wrap text-center">
                                <div className="movie-img">
                                    <Link to={`/movie/${movie.id}`} tabIndex="-1">
                                        <img src={`https://isynchro-hub.ireason.mk/poster/${movie.id}.jpg`}
                                             className={"card-img"} alt=""/>
                                    </Link>
                                    {/*<button title="Watchlist" className="Watch-list-btn" type="button" tabIndex="-1">*/}
                                    {/*    <i className="zmdi zmdi-plus"/></button>*/}
                                </div>
                                <div className="movie-content">
                                    <h3 className="title"><Link to={`/movie/${movie.id}`}
                                                                tabIndex="-1">{movie.name}</Link>
                                    </h3>
                                    <span>Quality : HD</span>
                                    <div className="movie-btn">
                                        <Link to={`/movie/${movie.id}`} className="btn-style-hm4-2 animated"
                                              tabIndex="-1">
                                            Watch Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    })
                }
            </> : <>
                <div className={"col-12 mb-3 text-light"}>
                    <h3 className={"text-light"}>Unfortunately, we did not find a movie that matches what you are
                        looking for...</h3>
                    <h4 className={"text-light"}>But you can take a look at the movies that we have:</h4>
                </div>
                {
                    movieData.map(movie => {
                        return <div key={movie.id} className="col-sm-12 col-md-4 col-lg-3 item movie-wrap-plr "
                                    aria-hidden="true" style={{width: "289px"}}>
                            <div className="movie-wrap text-center">
                                <div className="movie-img">
                                    <Link to={`/movie/${movie.id}`} tabIndex="-1">
                                        <img src={`https://isynchro-hub.ireason.mk/poster/${movie.id}.jpg`}
                                             className={"card-img"} alt=""/>
                                    </Link>
                                    {/*<button title="Watchlist" className="Watch-list-btn" type="button" tabIndex="-1">*/}
                                    {/*    <i className="zmdi zmdi-plus"/></button>*/}
                                </div>
                                <div className="movie-content">
                                    <h3 className="title"><Link to={`/movie/${movie.id}`}
                                                                tabIndex="-1">{movie.name}</Link>
                                    </h3>
                                    <span>Quality : HD</span>
                                    <div className="movie-btn">
                                        <Link to={`/movie/${movie.id}`} className="btn-style-hm4-2 animated"
                                              tabIndex="-1">
                                            Watch Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    })
                }
            </>
        }
    </div>
}