import {ToastContainer} from "react-toastify";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import {NavigationBar} from "./Navigation/NavigationBar";
import {HomePage} from "./HomePage/HomePage";
import {MovieDetails} from "./MovieDetails/MovieDetails";
import {SearchMovies} from "./HomePage/SearchMovies";
import {Footer} from "./Navigation/Footer";

function App() {
    return (
        <div className={"main-wrapper bg-black"}>
            <ToastContainer className={"m-3"}/>
            <Router>
              <NavigationBar/>
                  <Switch>
                      <Route exact path={"/"} component={HomePage}/>
                      <Route exact path={"/search"} component={SearchMovies}/>
                      {/*<Route exact path={"/about"} component={About}/>*/}
                      <Route path={"/movie/:id"} component={MovieDetails}/>
                      {/*<Route exact path={"/upload"} component={UploadVideo}/>*/}

                      <Redirect to={"/"} />
                  </Switch>
                <Footer/>
            </Router>
        </div>
);
}

export default App;
